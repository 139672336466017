.title-1 {
  font-size: 1.875rem;
  line-height: 1.2;
}

.title-1-xl {
  font-size: 3.5rem;
  line-height: 1.325;

  @media screen and (min-width: 768px) {
    font-size: 3.875rem;
  }
}

.title-2 {
  font-size: 1.875rem;
  line-height: 1.2;
}

.title-4 {
  font-size: 1.25rem;
  line-height: 1.2;
}

h1 {
  @extend .title-1;
  margin: 0;
}

h2 {
  @extend .title-2;
  margin: 0;
}

h4,
h5 {
  @extend .title-4;
  margin: 0;
}

p,
span {
  font-size: 1.1875rem;
  line-height: 22.8px;
}

p,
pre {
  font-family: Arial, sans-serif;
}

.sans-serif {
  font-family: Arial, sans-serif;
}
