.custom-button {
  padding: 10px 20px;
  text-decoration: none;
  color: inherit;
  border-radius: 20px;

  -webkit-box-shadow: -5px 5px 10px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: -5px 5px 10px 0px rgb(0 0 0 / 20%);
  box-shadow: -5px 5px 10px 0px rgb(0 0 0 / 20%);

  &.grey {
    background: $grey;
  }

  &.green {
    background: $green;
  }

  &.orange {
    background: $orange;
  }

  font-size: 40px;
}

.orange-box {
  background: $orange;
  border-radius: 20px;
}
