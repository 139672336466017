$green: #e3fad8;
$orange: #fcd2a4;
$grey: #eceae8;
$red: #cf0005;
$pink: #ffe4de;

.bg-green {
	background: $green;

	@media screen and (max-width: 960px) {
		background: rgba(227, 250, 216, 0.85);
	}
}

.bg-orange {
	background: $orange;

	@media screen and (max-width: 960px) {
		background: rgba(252, 210, 164, 0.85);
	}
}

.bg-pink {
	background: $pink;
}

.bg-grey {
	background: $grey;
	@media screen and (max-width: 960px) {
		background: rgba(236, 234, 232, 0.85);
	}
}

.color-green {
	color: $green;
}
.color-pink {
	color: $pink;
}

.color-orange {
	color: $orange;
}

.color-grey {
	color: $grey;
}

.color-black {
	color: black;
}
