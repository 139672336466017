.privacy-wrapper {
  height: 100%;
  width: 100%;
  background: $grey;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

table,
ol,
ul {
  * {
    font-family: "Arial", sans-serif;
  }
  font-family: "Arial", sans-serif;
}
.privacy {
  height: 100%;
  width: 100%;
  max-width: 960px;
  background: $grey;
  margin: 0 auto;
  padding: 0 2rem;
}

.cookie-button,
.back-button,
.privacy-policy-button {
  background: $grey;
  padding: 0.75rem 1.525rem;
  border-radius: 20px;
  margin-right: 1rem;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(0);
  width: fit-content;
  margin: 0 auto;
  border: none;
  outline: none;
  display: inline-block;

  background: $orange;
  color: black;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    background: black;
    color: $green;
  }
}

.back-button {
  background: $green;
}

.privacy-policy-button {
  background: $grey;
}

.back-button,
.privacy-policy-button {
  text-decoration: none;
  color: black;
  margin-bottom: 2rem;
}
