body {
  background: linear-gradient(
    180deg,
    $grey 0%,
    $green 25%,
    $grey 40%,
    $orange 48%,
    $grey 64%,
    $orange 80%,
    $green 100%
  );

  &.noscroll {
    overflow: hidden;
  }
}

.main-wrapper {
  display: flex;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  flex-wrap: wrap;
  > div {
    width: 100%;
    padding-top: 1.25rem;
    padding-bottom: 5rem;
  }
}

.identity {
  position: sticky;
}

a {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.header {
  text-align: center;
  position: fixed;
  padding: 1rem 0;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  padding-bottom: 2rem !important;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  .navigation {
    width: fit-content;
    margin: 0 auto;
    padding: 0.475rem 0;

    @media screen and (min-width: 768px) {
      margin-top: 0.25rem;
    }

    @media screen and (max-width: 768px) {
    }

    margin-top: 0.5rem;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  .title {
    @include shadow;
    color: initial;
    display: inline-block;
    padding: 0.5rem 1rem;
    display: inline-block;
    background: linear-gradient(0deg, #eceae8, #eceae8),
      linear-gradient(180deg, #ffd9ad 0%, #d2f3c3 100%);
    border-radius: 20px;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      background: linear-gradient(180deg, $orange 0%, $green 100%);
    }
  }
}
.character-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  img {
    position: absolute;
    width: 100%;
    height: auto;
    filter: blur(20px);
  }
  img,
  canvas {
    object-fit: cover;
    transition: 0.6s opacity ease-in-out;
    width: 100%;
    height: 100%;
    object-position: left top;

    @media screen and (max-width: 797px) {
      object-position: 6% 50%;
      height: 100vh;
    }
  }
}

.institution {
  display: block;
}
@media screen and (min-width: 768px) {
  .main-wrapper > div {
    width: 50%;
  }
}
.navigation ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  .active {
    background: black;
    color: $green;
  }
  li {
    list-style-type: none;
    position: relative;
    a,
    button {
      font-size: 1.2rem;
      @include shadow;
      background: $grey;
      padding: 0.25rem 0.5rem;
      text-decoration: none;
      color: initial;
      border-radius: 20px;
      transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
      cursor: pointer;
      outline: none;
      border: none;
      display: block;

      @media screen and (max-width: 768px) {
        padding: 0.475rem;
      }

      &:focus-visible {
        outline: 2px solid black;
      }

      &:not(.disabled):hover {
        background: black;
        color: $green;
      }
    }
  }

  .livestream-button {
    background: $red;
    color: white;

    span {
      line-height: inherit;
      animation: 1s pulsate ease-in-out infinite;
    }
  }

  .submenu {
    position: absolute;

    left: 0;
    border-radius: 20px;
    font-size: 0.875rem;
    padding: 1rem 0;

    top: 230%;
    @media screen and (min-width: 768px) {
      top: 170%;
      padding: 0.3rem 0;
    }

    &.closed {
      display: none;
      visibility: hidden;
    }
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

footer {
  width: 100%;
}

.character-container {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 1600px;
  z-index: -4;
  > div {
    pointer-events: none;
  }
}

footer {
  position: relative;
}
.content {
  position: relative;

  padding-left: 1rem;
  @media screen and (min-width: 768px) {
    padding-left: 0;
  }

  @media screen and (max-width: 767px) {
    margin-top: 50vh;
  }
}
.intro-section {
  .links {
    text-align: center;
    margin: 3rem 0;

    a {
      padding: 0.2rem 0.9rem;
      border-left: 1px solid black;
      &:first-of-type {
        border: none;
      }

      @media screen and (max-width: 767px) {
        border: none;
      }
    }
  }
  a {
    @extend .title-4;
    text-decoration: none;
    color: initial;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      text-align: left;
      padding-left: 0;
    }
  }
}

.navigation ul li a {
  &.button-tickets {
    background: $orange;
  }
  &.button-info,
  &.button-timetable {
    &:hover,
    &.active {
      color: $green;
    }
  }

  &:not(.disabled):hover,
  &[aria-expanded="true"] {
    background: black;
  }

  &.button-tickets,
  &.button-speakers {
    &:hover,
    &.active,
    &[aria-expanded="true"] {
      color: $orange;
    }
  }
}
