@mixin shadow {
  box-shadow: -2px 4px 18px 0px rgba(104, 89, 72, 0.15);
}

.bubble {
  @include shadow;
  border-radius: 20px;
  transition: all .1s ease-in-out;


  &.with-hover{
	  cursor: pointer;
	  &:hover {
		box-shadow: -2px 4px 18px 2px rgba(104, 89, 72, 0.25);
		filter:contrast(.92);
	}
}

  @extend .mt-14;
  &.large {
    padding: 1.142rem;
  }

  &.medium {
    padding: 1.142rem;
    max-width: 85%;

    @media screen and (min-width: 960px) {
      max-width: 494px;
    }
  }

  &.small {
    padding: 6px 16px;
    width: fit-content;
  }

  &.align-right {
    margin-right: 1rem;
    margin-left: auto;
  }
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.mt-12 {
  margin-top: 0.75rem;
}

.mt-14 {
  margin-top: 0.875rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mt-18 {
  margin-top: 1.125rem;
}

.mt-24 {
  margin-top: 1.5rem;
}

.mt-36 {
  margin-top: 2.25rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.disabled {
  color: $grey;
  filter: contrast(0.7);
  &:hover {
    cursor: default;
    // background: inherit;
  }

  &:active {
    pointer-events: none;
  }
}
