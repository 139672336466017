.speakers-list {
  text-align: center;
}

.speakers-bubble {
  margin-right: 1rem;
  opacity: 1;
  transform: translateY(0);
}

.speaker {
  margin: 2.125rem 0;

  &:first-of-type {
    margin-top: 0;
  }

  .link {
    display: inline-block;
    margin-top: 2rem;
  }

  .speaker-name,
  .duo-sign {
    display: inline-block;
    @extend .title-1-xl;
    margin: 0;
  }
  .duo-sign {
    margin-right: 1rem;
  }

  .reset-button {
    margin: 0 auto;
    border: none;
    outline: none;
	background: none;
  }

  .bio-tag {

	@extend .reset-button;

    background: $grey;
    padding: 0.75rem 1.525rem;
    border-radius: 20px;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(0);
    display: block;
    width: fit-content;

    font-family: inherit;

    &:hover,
    &:focus-visible {
      background: black;
      color: $green;
    }

    &.hide {
      opacity: 0;
      transform: translateY(-10px);
      pointer-events: none;
    }
  }
}

.second-speaker {
  margin-top: 0rem;
}

.speaker-bio {
  display: block;
  opacity: 0;
  transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(-30px);
  z-index: -1;
  margin-top: -20px;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}
