.archive-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 4rem 1rem;
}

.content-wrapper {
  margin-top: 4rem;
}

.archive-video-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.archive-video {
  max-width: 480px;
  width: 33.33%;

  padding: 0 1rem;

  .archive-video-player {
    width: 100%;
    height: auto !important;
    aspect-ratio: 16/9;
  }
}

.filters {
  display: flex;

  > div {
    margin-right: 1rem;
  }
  button {
    min-width: 60px;
    text-transform: capitalize;
    cursor: pointer;
    display: block;
    background: transparent;
    border: none;

    font-size: 1.2em;
    padding: 0.5em 0.5em;

    &.active {
      font-weight: bold;
    }
  }
}

.archive-images {
  display: flex;
  flex-wrap: wrap;

  .image-wrapper {
    width: calc(100% - 2rem);
    margin: 1rem;
    height: fit-content;

    @media screen and (min-width: 1028px) {
      width: calc(50% - 2rem);
    }

    @media screen and (min-width: 1028px) {
      max-width: 480px;
      width: 30%;
    }
    button {
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 100%;
      aspect-ratio: 4/3;
      display: block;
      background: transparent;
      border: none;
      outline: none;
      text-decoration: none;
      text-align: center;
      img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .archive-image-caption p {
    color: initial;

    font-size: 0.875rem;
    font-style: italic;
  }
}

.flip {
  transform: rotate(180deg);
}

.dialog {
  display: flex;
  justify-content: center;
  align-items: center;

  [data-reach-dialog-content] {
    width: auto;
    padding: 0;
    .close-button {
      top: 20px;
      right: 20px;
      background: $orange;
    }

    img {
      max-height: 90vh;
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
    }
    background: $green;
  }

  span p {
    padding: 0 1rem;
  }

  .dialog-button {
    position: fixed;
    left: 20px;
    top: 50%;

    button {
      background: transparent;
      outline: none;
      border: none;
      margin: 0;
      padding: 0.8em 0.4em 1em 0.4em;
      line-height: 0;
      font-size: 2.5rem;
      cursor: pointer;
    }

    &.next {
      left: auto;
      right: 20px;
    }
  }
}

.document-list {
  padding: 0;
  margin: 0;
}

.document-item {
  list-style-type: none;

  a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    svg {
      display: block;
      width: 34px;
      height: auto;
      transform: translateY(3px);
      margin-right: 0.4rem;
    }
  }
}
