.programme-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  flex-wrap: wrap;

  @extend .title-4;

  .day-wrapper {
    width: 100%;
    padding-top: 0 !important;
    max-height: 75vh;
    overflow: auto;
    .title-wrapper {
      background: $green;
      padding: 1.142rem;
      position: sticky;
      top: 0;
      margin: 0 -1.142rem;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      @extend .title-2;
    }
    h4 {
      @extend .title-2;
    }

    &:nth-child(2) {
      margin-right: 1rem;
      margin-left: auto;
    }
  }
}

.programme-item {
  // padding: 10px 20px 20px 20px;
  margin-top: 2rem;
  h4,
  h5 {
    font-weight: normal;
  }

  .time {
    display: block;
    margin: 0.5rem 0;
  }
  span {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
