html body .termsfeed-com---nb {
  position: fixed;
  bottom: 10px !important;
  left: 10px !important;
  right: 10px !important;
  background: $grey !important;
//   z-index: 10;
  border-radius: 29px;
  padding: 6px 24px 14px 24px !important;
  box-shadow: 0px -7px 15px 12px rgb(104 89 72 / 15%);
  max-width: 460px;
  font-size: 12px;

  .cc-nb-title {
    font-size: 20px;
  }

  .cc-nb-text {
    font-size: 14px;
  }

  .cc-nb-buttons-container button {
    font-size: 12px;
  }
  .cc-nb-main-container {
    padding: 0;
    padding-top: 1rem;
  }
  button {
    background: $grey;
    padding: 0.75rem 1.525rem;
    border-radius: 20px;
    margin-right: 1rem;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(0);
    width: fit-content;
    margin: 0 auto;
    border: none;
    outline: none;

    background: $orange !important;
    color: black !important;

    &:hover,
    &:focus-visible {
      background: black;
      color: $green;
    }
  }
  transition: opacity 0.3s ease-in-out;
}

.termsfeed-com---is-hidden {
  display: none;
}

.termsfeed-com---pc-overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  display: none;

  &.termsfeed-com---is-visible {
    display: flex;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li[active="true"] button,
  li:hover button {
    background: $orange;
  }
  button {
    background: $grey;
    padding: 0.75rem 1.525rem;
    border-radius: 20px;
    margin-right: 1rem;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(0);
    width: fit-content;
    margin: 0 auto;
    border: none;
    outline: none;

    li[active="true"] & {
      background: $orange;
    }
    &:hover,
    &:focus-visible {
      background: black;
      color: $green;
    }
  }
  .termsfeed-com---pc-dialog {
    background: $grey;

    .cc-cp-foot .cc-cp-foot-button .cc-cp-foot-save {
      background: $orange;
      color: black;
      margin: 14px 0;
      border-radius: 29px;
    }
  }
}

// .termsfeed-com---reset {
//   display: none;
// }
