.livestream-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);

  > div {
    z-index: 4;
  }
}

.inner-wrapper {
  width: 100%;
  max-width: 1280px;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
}

.livestream-title {
  margin-bottom: 1rem;
}

.sound-button,
.close-button,
.fullscreen-button {
  z-index: 10;
  position: absolute;
  outline: none;
  border: none;

  padding: 0.75rem 0.925rem;
  font-size: 0.5rem;
  border-radius: 20px;

  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(0);
  display: block;
  width: fit-content;

  font-family: inherit;

  &:hover,
  &:focus-visible {
    background: black;
    color: $green;
  }

  &.hide {
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}

.fullscreen-button {
  right: 1%;
  bottom: 1%;

  @media screen and (max-width: 768px) {
    bottom: -20%;
  }
}
.close-button {
  background: $green;
  right: 0;
  top: -8%;
  @media screen and (max-width: 768px) {
    top: 5%;
    right: 5%;
  }
}

.sound-button {
  background: $orange;
  left: 1%;
  bottom: 1%;
  @media screen and (max-width: 768px) {
    bottom: -20%;
  }
}

.player-wrapper {
  z-index: 4;
  height: auto;
  width: 100%;
  top: 0;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  &.bumper {
    border-radius: 5px;
    overflow: hidden;
    .bumper {
      z-index: 100;
    }
  }
  &.livestream {
    .livestream {
      z-index: 100;
    }
  }
}

.react-player {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
  position: absolute;
  top: 0;
  left: 0;

  &.livestream {
    max-width: 95%;
    top: 2.5%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 2px 2px #e16646;
  }
}

.frame {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 1;
}

.play-button-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 123123123;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
  border: none;
  cursor: pointer;

  svg {
    fill: $orange;
    max-width: 30%;
  }
}
