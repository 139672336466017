@import "vars.scss";
@import "utility.scss";
@import "typography.scss";
@import "button.scss";
@import "home.scss";
@import "speakers.scss";
@import "programme.scss";
@import "archive.scss";
@import "privacy.scss";
@import "cookies.scss";
@import "livestream.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}
html,
body,
main {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  background: $grey;
  height: 100%;
}

@media screen and (max-width: 767px) {
  html {
    font-size: 12px;
  }
}

.min-h-screen {
  min-height: 100vh;
}

footer {
  position: relative;
  text-align: right;
  max-width: 1680px;
  margin: 0 auto;
  padding: 1.142rem;
}
